import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdDone } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import DateSelection from "./DateSelection";
import moment from "moment";
import {
  checkTimeDifference,
  getItemFromLocalStorage,
  setRedirectPage,
} from "../../helpers/utils";
import SurgeryDetails from "./SurgeryDetails";
import ProgressBar from "../../components/ProgressBar";
import {
  PAGES,
  PROGRESS_STATE,
  PROGRESS_STEP_LABELS,
} from "../../helpers/constants";
import OtSelection from "./OtSelection";
import PatientInfo from "./PatientInfo";
import QuickRequestDetails from "./QuickRequestDetails";
import AdditionalInfo from "./AdditionalInfo";
import { getPatientInfoFields } from "./Actions/quickBookingActions";
import styles from "./index.module.css";

const QuickBookingRequest = () => {
  const dispatch = useDispatch();

  const {
    units = [],
    department,
    crew_details: chiefSurgeon,
  } = getItemFromLocalStorage("user") || {};

  const { title } = useSelector((state) => state.quickInputReducer);

  const [step, setStep] = useState(0);
  const [surgeryDate, setSurgeryDate] = useState(moment());
  const [checkDate, setCheckDate] = useState(false);
  const [closureTime, setClosureTime] = useState(false);
  const [checkToday, setCheckToday] = useState(false);
  const [bookingMode, setBookingMode] = useState(false);

  const [proStep, setProStep] = useState([
      {
        key: 1,
        label: PROGRESS_STEP_LABELS.SELECT_OT,
        icon: MdDone,
        status: PROGRESS_STATE.PENDING,
      },
      {
        key: 2,
        label: PROGRESS_STEP_LABELS.PATIENT_INFO,
        icon: MdDone,
        status: PROGRESS_STATE.PENDING,
      },
      {
        key: 3,
        label: PROGRESS_STEP_LABELS.ADDITIONAL_INFO,
        icon: MdDone,
        status: PROGRESS_STATE.PENDING,
      },
    ]),
    [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    setCheckDate(surgeryDate.isAfter(moment().add(1, "day")));
    setCheckToday(surgeryDate.isSame(moment(), "day"));
    setClosureTime(checkTimeDifference(units[0]?.ot_booking_closure_time));
    setBookingMode((!checkDate && !closureTime) || checkToday);
  }, [surgeryDate, checkDate, closureTime, checkToday]);

  useEffect(() => {
    setRedirectPage(PAGES.QUICK_BOOKING);
    dispatch(getPatientInfoFields(units[0]?.id));
  }, []);

  const handleSetSteps = (currentStep, nextStep) => {
    setProStep(
      proStep.map((step) => {
        if (step.key === currentStep) {
          return { ...step, status: PROGRESS_STATE.COMPLETED };
        } else if (step.key === nextStep) {
          return { ...step, status: PROGRESS_STATE.PENDING };
        } else {
          return { ...step };
        }
      })
    );
  };

  const dateSelectionProps = {
    units,
    surgeryDate,
    closureTime,
    setClosureTime,
    setSurgeryDate,
    setStep,
    chiefSurgeon,
    speciality: department,
  };

  const surgeryDetailsProps = {
    closureTime,
    setClosureTime,
    setStep,
  };

  const otSelectionProps = {
    units,
    setActiveStep,
    handleSetSteps,
  };

  const patientInfoProps = {
    setActiveStep,
    handleSetSteps,
  };

  const specialPharmacyListProps = {
    handleSetSteps,
    setActiveStep,
    setStep,
  };

  const handleBack = () => {
    if (step > 0) {
      if (step === 3 && activeStep === 4) {
        setStep(2);
        setActiveStep(3);
      } else if (step === 2 && activeStep > 1) {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      } else {
        setStep((prevStep) => prevStep - 1);
      }
    }
  };

  return (
    <div className={styles["quick-booking"]}>
      {step > 1 && (
        <div className={styles["quick-booking__back"]}>
          <IoIosArrowBack size={26} onClick={handleBack} />
        </div>
      )}
      {step === 0 && <DateSelection {...dateSelectionProps} />}
      {step === 1 && <SurgeryDetails {...surgeryDetailsProps} />}
      {step === 2 && (
        <div className={styles["quick-booking__progress-page"]}>
          {activeStep !== 4 && (
            <ProgressBar
              steps={proStep}
              setSteps={setProStep}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          )}
          {title && <h2 className={styles["quick-booking__title"]}>{title}</h2>}
          {activeStep === 1 && <OtSelection {...otSelectionProps} />}
          {activeStep === 2 && <PatientInfo {...patientInfoProps} />}
          {activeStep === 3 && <AdditionalInfo {...specialPharmacyListProps} />}
        </div>
      )}
      {step === 3 && <QuickRequestDetails />}
    </div>
  );
};

export default QuickBookingRequest;
