import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAdditionalItems, setTitle } from "../../action";
import { FaCartPlus } from "react-icons/fa";
import { CgTrash } from "react-icons/cg";
import Modal from "../../../../components/Modal";
import NewButton from "../../../../components/NewButton";
import TextInput from "../../../../components/TextInput";
import Counter from "../../../../components/Counter";
import {
  BUTTON_TYPES,
  GLOBAL_CONSTANTS,
  GLOBAL_MESSAGES,
  RADIO_CONSTANTS,
  WORDINGS,
} from "../../../../helpers/constants";
import {
  handleAlertError,
  handleAlertSuccess,
} from "../../../../helpers/utils";
import { ReactComponent as EditIcon } from "../../../../assets/Icons/editIcon.svg";
import ButtonContainer from "../../../../components/ButtonContainer";
import RadioButton from "../../../../components/RadioButton";
import styles from "./index.module.css";

const AdditionalItems = (props) => {
  const { isDone } = props;

  const dispatch = useDispatch();

  const { specialItem } = useSelector((state) => state.quickInputReducer);

  const [isSpecialItems, toggleSpecialItems] = useState(
    specialItem?.isSpecialItems || "no"
  );
  const [specialPharmacySupplies, setSpecialPharmacySupplies] = useState([]);
  const [vendor, setVendor] = useState("");
  const [item, setItem] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [selectedPharmacyItem, setSelectedPharmacyItem] = useState(null);
  const [editPharmacyItem, toggleEditPharmacyItem] = useState(false);
  const [deletePharmacyItem, toggleDeletePharmacyItem] = useState(false);
  const [viewList, toggleViewList] = useState(false);
  const [newTempList, setNewTempList] = useState([]);
  const vendorRef = useRef(null);

  useEffect(() => {
    dispatch(
      setTitle(`${WORDINGS.ADDITIONAL_INFO} : ${WORDINGS.ADDITIONAL_ITEMS}`)
    );
  }, []);

  useEffect(() => {
    if (specialItem?.specialPharmacy?.length > 0) {
      setSpecialPharmacySupplies(specialItem.specialPharmacy);
    }
  }, [specialItem]);

  const handleAdd = () => {
    if (item && quantity > 0 && item?.trim()) {
      if (selectedPharmacyItem?.item) {
        setSpecialPharmacySupplies(
          specialPharmacySupplies?.map((data, index) => {
            if (index === selectedPharmacyItem?.index) {
              return {
                vendor: vendor?.trim(),
                item: item?.trim(),
                quantity: quantity,
              };
            } else return data;
          })
        );
        dispatch(handleAlertSuccess(GLOBAL_MESSAGES.SUCCESSFULLY_UPDATED));
      } else {
        setSpecialPharmacySupplies([
          ...specialPharmacySupplies,
          { vendor: vendor?.trim(), item: item?.trim(), quantity: quantity },
        ]);
        dispatch(handleAlertSuccess(GLOBAL_MESSAGES.SUCCESSFULLY_ADDED));
      }

      setVendor("");
      setItem("");
      setQuantity(1);
      setSelectedPharmacyItem(null);
      toggleEditPharmacyItem(false);
    } else {
      dispatch(handleAlertError(GLOBAL_MESSAGES.FILL_REQUIRED_FIELDS));
    }
  };

  const handleUpdate = () => {
    if (item && quantity > 0 && item?.trim()) {
      if (selectedPharmacyItem?.item) {
        setNewTempList(
          newTempList?.map((data, index) => {
            if (index === selectedPharmacyItem?.index) {
              return {
                vendor: vendor?.trim(),
                item: item?.trim(),
                quantity: quantity,
              };
            } else return data;
          })
        );
        // dispatch(handleAlertSuccess(GLOBAL_MESSAGES.SUCCESSFULLY_UPDATED));
      }
    }
    setVendor("");
    setItem("");
    setQuantity(1);
    setSelectedPharmacyItem(null);
    toggleEditPharmacyItem(false);
  };
  useEffect(() => {
    if (selectedPharmacyItem?.item) {
      setVendor(selectedPharmacyItem?.vendor);
      setItem(selectedPharmacyItem?.item);
      setQuantity(selectedPharmacyItem?.quantity);
    } else {
      setVendor("");
      setItem("");
      setQuantity(1);
    }

    if (vendorRef?.current) vendorRef?.current?.focus();
  }, [selectedPharmacyItem]);

  useEffect(() => {
    if (viewList) {
      setNewTempList(specialPharmacySupplies);
    } else {
      setNewTempList([]);
    }
  }, [viewList]);

  const confirmItems = [
    {
      label: WORDINGS.YES,
      name: RADIO_CONSTANTS.YES,
    },
    {
      label: WORDINGS.NO,
      name: RADIO_CONSTANTS.NO,
    },
  ];

  const decrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const increment = () => {
    setQuantity(quantity + 1);
  };

  const handleSubmit = () => {
    if (
      isSpecialItems === RADIO_CONSTANTS.YES &&
      specialPharmacySupplies.length > 0
    ) {
      dispatch(
        setAdditionalItems({
          isSpecialItems,
          specialPharmacy: specialPharmacySupplies,
        })
      );
      isDone();
    } else if (isSpecialItems === RADIO_CONSTANTS.NO) {
      setSpecialPharmacySupplies([]);
      dispatch(setAdditionalItems({ isSpecialItems }));
      isDone();
    } else {
      dispatch(handleAlertError(WORDINGS.PLEASE_ADD_ADDITIONAL_ITEMS));
    }
  };

  const buttonProps = {
    ...props,
    isDone: handleSubmit,
  };

  return (
    <div className={styles["pharmacy-supplies"]}>
      {viewList && (
        <Modal
          fullModal
          isOpen={viewList}
          onCancel={() => toggleViewList(false)}
          title={WORDINGS.SELECTED_SUPPLIES}
          closeButton
        >
          <div className={styles["pharmacy-suplies__modal"]}>
            <div className={styles["pharmacy-supplies__modal-list"]}>
              {Object.keys(newTempList).length > 0 ? (
                <div className={styles["pharmacy-supplies__list"]}>
                  {Object.values(newTempList)?.map((item, index) => (
                    <div
                      key={item?.vendor}
                      className={styles["pharmacy-supplies__list__item"]}
                    >
                      <div>{item?.item}</div>
                      <div
                        onClick={() => {
                          toggleEditPharmacyItem(true);
                          setSelectedPharmacyItem({ ...item, index });
                        }}
                        className={
                          styles["pharmacy-supplies__list__item__edit-btn"]
                        }
                      >
                        <div>{item?.quantity}</div>
                        <EditIcon />
                      </div>
                      <div
                        onClick={() => {
                          toggleDeletePharmacyItem(true);
                          setSelectedPharmacyItem(item);
                        }}
                        className={
                          styles["pharmacy-supplies__list__item__delete-btn"]
                        }
                      >
                        <CgTrash color="#B51616" fontSize={24} />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={styles["pharmacy-supplies__no-items"]}>
                  {WORDINGS.NO_ITEMS_ADDED}
                </div>
              )}
              {deletePharmacyItem && (
                <Modal
                  isOpen={deletePharmacyItem}
                  onCancel={() => toggleDeletePharmacyItem(false)}
                >
                  <div className={styles["pharmacy-supplies__delete"]}>
                    <div className={styles["pharmacy-supplies__delete__title"]}>
                      <span>{`${GLOBAL_CONSTANTS.ITEM}: ${selectedPharmacyItem?.item}`}</span>
                    </div>
                    <div
                      className={
                        styles["pharmacy-supplies__delete__button-container"]
                      }
                    >
                      <NewButton
                        shadow
                        onClick={() => {
                          setNewTempList(
                            newTempList?.filter(
                              (item) => item !== selectedPharmacyItem
                            )
                          );
                          toggleDeletePharmacyItem(false);
                          setSelectedPharmacyItem(null);
                        }}
                        disabled={quantity < 1}
                      >
                        {BUTTON_TYPES.REMOVE}
                      </NewButton>
                      <NewButton
                        shadow
                        cancelButton
                        onClick={() => {
                          toggleDeletePharmacyItem(false);
                          setSelectedPharmacyItem(null);
                        }}
                      >
                        {BUTTON_TYPES.CANCEL}
                      </NewButton>
                    </div>
                  </div>
                </Modal>
              )}
              {editPharmacyItem && (
                <Modal
                  isOpen={editPharmacyItem}
                  onCancel={() => toggleEditPharmacyItem(false)}
                >
                  <div className={styles["pharmacy-supplies__update"]}>
                    <div className={styles["pharmacy-supplies__update__title"]}>
                      <span>{`${GLOBAL_CONSTANTS.ITEM}: ${selectedPharmacyItem?.item}`}</span>
                    </div>
                    <Counter
                      count={quantity}
                      increment={increment}
                      decrement={decrement}
                      minimumValue={1}
                    />
                    <div
                      className={
                        styles["pharmacy-supplies__update__button-container"]
                      }
                    >
                      <NewButton
                        shadow
                        onClick={() => {
                          if (quantity > 0) {
                            handleUpdate();
                          }
                        }}
                        disabled={quantity < 1}
                      >
                        {BUTTON_TYPES.UPDATE}
                      </NewButton>
                      <NewButton
                        shadow
                        cancelButton
                        onClick={() => {
                          toggleEditPharmacyItem(false);
                          setSelectedPharmacyItem(null);
                        }}
                      >
                        {BUTTON_TYPES.CANCEL}
                      </NewButton>
                    </div>
                  </div>
                </Modal>
              )}
            </div>
            <NewButton
              largeButton
              onClick={() => {
                setSpecialPharmacySupplies(newTempList);
                toggleViewList(false);
              }}
            >
              {WORDINGS.DONE}
            </NewButton>
          </div>
        </Modal>
      )}
      <section className={styles["pharmacy-supplies__item"]}>
        <label>{WORDINGS.ARE_ANY_ADDITIONAL_ITEMS}</label>
        {confirmItems?.map((item, index) => (
          <RadioButton
            name={item?.name}
            value={isSpecialItems}
            onChange={(value) => toggleSpecialItems(value)}
            label={item?.label}
            key={index}
          />
        ))}
      </section>
      {isSpecialItems === RADIO_CONSTANTS.YES && (
        <div className={styles["pharmacy-supplies__input-group"]} id="test">
          <section
            className={styles["pharmacy-supplies__input-group__heading"]}
          >
            <label>{WORDINGS.SELECT_REQUIRED_ADDITIONAL_ITEM}</label>
            <div onClick={() => toggleViewList(true)}>
              <FaCartPlus size={20} color="#D3D8DD" />
              <span>{specialPharmacySupplies?.length}</span>
            </div>
          </section>
          <TextInput
            label="Vendor"
            type="text"
            autoFocus
            value={vendor}
            onChange={(value) => setVendor(value?.trimStart())}
            // required
          />
          <TextInput
            label="Item"
            type="text"
            value={item}
            onChange={(value) => setItem(value?.trimStart())}
            required
          />
          <div className={styles["pharmacy-supplies__button-container"]}>
            <Counter
              count={quantity}
              increment={increment}
              decrement={decrement}
              label="Quantity"
              minimumValue={1}
            />
            <button
              className={styles["pharmacy-supplies__button-container__button"]}
              onClick={handleAdd}
            >
              {BUTTON_TYPES.ADD}
            </button>
          </div>
        </div>
      )}
      <ButtonContainer {...buttonProps} />
    </div>
  );
};

export default AdditionalItems;
