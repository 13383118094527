import { QUICK_BOOKING_ACTIONS } from "./constants";

const initialState = {
  quickBookingAvailability: {},
  patientList: [],
  patientInformationFields: [],
  swcList: [],
  peiList: [],
  anaesthesiaList: [],
};

export const quickBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUICK_BOOKING_ACTIONS.GET_QUICK_BOOKING_AVAILABILITY_SUCCESS:
      return {
        ...state,
        quickBookingAvailability: action.payload,
      };
    case QUICK_BOOKING_ACTIONS.GET_PATIENT_LIST_SUCCESS: {
      return { ...state, patientList: action.payload };
    }
    case QUICK_BOOKING_ACTIONS.GET_PATIENT_INFORMATION_FIELDS_SUCCESS: {
      return { ...state, patientInformationFields: action.payload };
    }
    case QUICK_BOOKING_ACTIONS.GET_SWC_LIST_SUCCESS: {
      return { ...state, swcList: action.payload };
    }
    case QUICK_BOOKING_ACTIONS.GET_PEI_LIST_SUCCESS: {
      return { ...state, peiList: action.payload };
    }
    case QUICK_BOOKING_ACTIONS.GET_ANAESTHESIA_LIST_SUCCESS:
      return { ...state, anaesthesiaList: action.payload };

    default:
      return state;
  }
};
