import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../action";
import { getQuickSlotAvailability } from "../Actions/quickBookingActions";
import moment from "moment";
import NewButton from "../../../components/NewButton";
import NewLoader from "../../../components/NewLoader";
import Modal from "../../../components/Modal";
import UpdateSurgeryTime from "../UpdateSurgeryTime";
import {
  checkObjectNotEmpty,
  handleAlertError,
  joinClass,
} from "../../../helpers/utils";
import {
  BUTTON_TYPES,
  GLOBAL_MESSAGES,
  WORDINGS,
} from "../../../helpers/constants";
import { INPUT_ACTIONS } from "../constants";
import { ReactComponent as EditICon2 } from "../../../assets/Icons/editIcon2.svg";
import styles from "./index.module.css";

const OtSelection = (props) => {
  const { units, setActiveStep, handleSetSteps } = props;
  const dispatch = useDispatch();
  const {
    quickBookingAvailability: { available_ot: otAvailable },
  } = useSelector((state) => state.quickBookingReducer);

  const {
    surgeryDate,
    surgeryDetails: { surgeryList = [] },
    otDetails,
  } = useSelector((state) => state.quickInputReducer);

  const {
    surgeryDetails: {
      startTime = null,
      endTime = 0,
      procedureTime,
      bufferTime,
    } = {},
  } = useSelector((state) => state.quickInputReducer);

  const [surgeryStartTime, setSurgeryStartTime] = useState(startTime);
  const [surgeryEndTime, setSurgeryEndTime] = useState(endTime);
  const [loader, toggleLoader] = useState(false);
  const [selectedOt, setSelectedOt] = useState(null);
  const [updateTime, toggleUpdateTime] = useState(false);

  useEffect(() => {
    dispatch(setTitle(WORDINGS.AVAILABLE_OT));
  }, []);

  useEffect(() => {
    if (checkObjectNotEmpty(otDetails)) {
      setSelectedOt(otDetails);
    }
  }, [otDetails]);

  useEffect(() => {
    toggleLoader(true);
    const params = {
      surgeryStartTime: +moment(surgeryStartTime),
      surgeryEndTime: +moment(surgeryEndTime),
      surgeryTypeId: surgeryList[0]?.id,
    };
    dispatch(getQuickSlotAvailability(units[0].id, params, toggleLoader));
  }, [surgeryStartTime, surgeryEndTime]);

  const updateTimeProps = {
    surgeryStartTime,
    surgeryEndTime,
    surgeryDate,
    setSurgeryStartTime,
    setSurgeryEndTime,
    bufferTime,
    procedureTime,
    setSelectedOt,
    toggleUpdateTime,
  };

  const handleSubmitProceed = () => {
    if (selectedOt) {
      dispatch({ type: INPUT_ACTIONS.SET_OT, payload: selectedOt });
      handleSetSteps(1, 2);
      setActiveStep(2);
    } else {
      dispatch(handleAlertError(GLOBAL_MESSAGES.SELECT_OT));
    }
  };

  return (
    <div className={styles["ot-selection"]}>
      <section>
        <div className={styles["ot-selection__timing"]}>
          <section>{`${WORDINGS.SURGERY_TIMING} (${WORDINGS.INCLUDES_CLEANING_DURATION})`}</section>
          <section>
            <div className={styles["ot-selection__surgery-time"]}>
              {`${moment(surgeryStartTime).format("hh:mm A")} - ${moment(
                surgeryEndTime
              ).format("hh:mm A")}`}
            </div>
            <NewButton
              className={styles["ot-selection__edit-button"]}
              onClick={() => toggleUpdateTime(true)}
            >
              <label className={styles["ot-selection__edit-button__label"]}>
                {BUTTON_TYPES.EDIT_TIME} <EditICon2 size={17} />
              </label>
            </NewButton>
          </section>
        </div>
      </section>
      <section className={styles["ot-selection__slot-list"]}>
        {loader ? (
          <NewLoader />
        ) : otAvailable?.length > 0 ? (
          <div className={styles["ot-selection__slot-data"]}>
            {otAvailable?.filter((ot) => ot.feasibility === "preferred")
              ?.length > 0 && (
              <div className={styles["ot-selection__slot-group"]}>
                <label>{WORDINGS.PREFERRED}</label>
                <div className={styles["ot-selection__slots"]}>
                  {otAvailable
                    ?.filter((ot) => ot.feasibility === "preferred")
                    ?.map((ot, index) => (
                      <div key={index}>
                        <div
                          className={joinClass(
                            styles["ot-selection__timing__ot"],
                            selectedOt?.id === ot?.id &&
                              styles["ot-selection__timing__ot-selected"]
                          )}
                          onClick={() => setSelectedOt(ot)}
                        >
                          {ot.name}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
            {otAvailable?.filter((ot) => ot.feasibility === "not_preferred")
              ?.length > 0 && (
              <div className={styles["ot-selection__slot-group"]}>
                <label>{WORDINGS.NON_PREFERRED}</label>
                <div className={styles["ot-selection__slots"]}>
                  {otAvailable
                    ?.filter((ot) => ot.feasibility === "not_preferred")
                    ?.map((ot, index) => (
                      <div key={index}>
                        <div
                          className={joinClass(
                            styles["ot-selection__timing__ot"],
                            selectedOt?.id === ot?.id &&
                              styles["ot-selection__timing__ot-selected"]
                          )}
                          onClick={() => setSelectedOt(ot)}
                        >
                          {ot.name}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>{WORDINGS.NOT_OT_AVAILABLE}</div>
        )}
      </section>
      <div className={styles["ot-selection__proceed-button"]}>
        <NewButton
          largeButton
          className={styles["ot-selection__proceed-button__bottom"]}
          onClick={handleSubmitProceed}
        >
          {BUTTON_TYPES.ENTER_PATIENT_INFO}
        </NewButton>
      </div>
      {updateTime && (
        <Modal
          isOpen={updateTime}
          onCancel={() => {
            toggleUpdateTime(false);
          }}
          closeButton
          bottomModal
          className={styles["ot-selection__modal-size"]}
          title={WORDINGS.UPDATE_TIMINGS}
        >
          <UpdateSurgeryTime {...updateTimeProps} />
        </Modal>
      )}
    </div>
  );
};

export default OtSelection;
