import { INPUT_ACTIONS } from "./constants";

const initialState = {
  specialityInfo: {},
  surgeryDate: null,
  surgeryDetails: {},
  otDetails: {},
  personalDetails: {},
  includedFields: {},
  mandatoryFields: {},
  infectionStatus: {},
  patientNotes: "",
  anaesthesia: {
    anaesthesiaTypes: [],
    pac: "",
  },
  confirmations: [],
  bloodBankAssist: "",
  specialItem: {
    isSpecialItems: "",
    specialPharmacy: {},
  },
  title: "",
};

export const quickInputReducer = (state = initialState, action) => {
  switch (action.type) {
    case INPUT_ACTIONS.SET_SURGERY_DATE:
      return { ...state, surgeryDate: action.payload };

    case INPUT_ACTIONS.SET_SURGERY_DETAILS:
      return { ...state, surgeryDetails: action.payload };

    case INPUT_ACTIONS.EDIT_SURGERY_TIME:
      return {
        ...state,
        surgeryDetails: {
          ...state.surgeryDetails,
          startTime: action.payload.startTime,
          endTime: action.payload.endTime,
        },
      };

    case INPUT_ACTIONS.SET_OT:
      return { ...state, otDetails: action.payload };

    case INPUT_ACTIONS.SET_PERSONAL_DETAILS:
      return { ...state, personalDetails: action.payload };

    case INPUT_ACTIONS.SET_INCLUDED_FIELDS:
      return { ...state, includedFields: action.payload };

    case INPUT_ACTIONS.SET_MANDATORY_FIELDS:
      return { ...state, mandatoryFields: action.payload };

    case INPUT_ACTIONS.SET_INFECTION_STATUS:
      return { ...state, infectionStatus: action.payload };

    case INPUT_ACTIONS.SET_PATIENT_NOTES:
      return { ...state, patientNotes: action.payload };

    case INPUT_ACTIONS.SET_ANAESTHESIA_DETAILS:
      return { ...state, anaesthesia: action.payload };

    case INPUT_ACTIONS.SET_CONFIRMATION_LOGS:
      return { ...state, confirmations: action.payload };

    case INPUT_ACTIONS.SET_BLOODBANK_ASSIST:
      return { ...state, bloodBankAssist: action.payload };

    case INPUT_ACTIONS.SET_ADDITIONAL_ITEMS:
      return { ...state, specialItem: action.payload };

    case INPUT_ACTIONS.SET_TITLE:
      return { ...state, title: action.payload };

    case INPUT_ACTIONS.SET_SPECIALITY_INFO:
      return { ...state, specialityInfo: action.payload };

    case INPUT_ACTIONS.RESET_INPUT_ACTIONS:
      return { ...initialState };

    default:
      return state;
  }
};
