export const QUICK_BOOKING_ACTIONS = {
  GET_QUICK_BOOKING_AVAILABILITY_SUCCESS:
    "GET_QUICK_BOOKING_AVAILABILITY_SUCCESS",
  GET_PATIENT_LIST_SUCCESS: "GET_PATIENT_LIST_SUCCESS",
  GET_PATIENT_INFORMATION_FIELDS_SUCCESS:
    "GET_PATIENT_INFORMATION_FIELDS_SUCCESS",
  GET_SWC_LIST_SUCCESS: "GET_SWC_LIST_SUCCESS",
  GET_PEI_LIST_SUCCESS: "GET_PEI_LIST_SUCCESS",
  GET_ANAESTHESIA_LIST_SUCCESS: "GET_ANAESTHESIA_LIST_SUCCESS",
};
