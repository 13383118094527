import React, { useState } from "react";
import AdvancedDropdown from "../AdvancedDropdown";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../helpers/constants";
import styles from "./index.module.css";
import { createTimeObject } from "../../helpers/utils";

const NewTimeDropdown = (props) => {
  const { title, selectedTime, selectedDate, setSelectedTime } = props;

  const [value, setValue] = useState(createTimeObject(selectedTime));

  const roundToNearest30Minutes = (time) => {
    const minutes = time.minute();
    const remainder = minutes % 30;
    if (remainder === 0) {
      return time;
    }
    return time.add(30 - remainder, "minutes").startOf("minute");
  };

  const valueTimes = () => {
    const data = [];
    let start;

    if (moment(selectedDate).isSame(moment(), "day")) {
      start = roundToNearest30Minutes(moment());
    } else if (moment(selectedDate).isAfter(moment(), "day")) {
      start = moment(selectedDate).startOf(DATE_TIME_FORMAT.DAY);
    } else {
      return data;
    }

    while (
      moment(start).isSameOrBefore(
        moment(selectedDate).endOf(DATE_TIME_FORMAT.DAY)
      )
    ) {
      data.push({
        id: moment(start)
          .set({ seconds: 0, milliseconds: 0 })
          .format(DATE_TIME_FORMAT.X)
          .toString(),
        time: moment(start),
        label: moment(start).format(DATE_TIME_FORMAT.hh_mm_A),
      });

      start = moment(start).add(30, "minute");
    }
    return data;
  };

  const handleChangeTime = (value) => {
    setValue(value);
    setSelectedTime(+moment(value?.time));
  };

  return (
    <div className={styles["new-time-dropdown"]}>
      <AdvancedDropdown
        value={value?.id > 0 ? value : 0}
        onChange={(value) => handleChangeTime(value)}
        options={valueTimes()}
        label={title}
        required
        isSearchable={false}
        placeholder="-Select-"
      />
    </div>
  );
};

export default NewTimeDropdown;
