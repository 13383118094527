import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIncludedFields, setMandatoryFields } from "../action";
import BottomNavigation from "../../../components/BottomNavigation";
import { PATIENT_FIELDS, WORDINGS } from "../../../helpers/constants";
import PersonalDetails from "./PersonalDetails";
import InfectionStatus from "./InfectionStatus";
import Notes from "./Notes";

const usePatientFields = (patientInformationFields) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const includedFields = {};
    const mandatoryFields = {};

    patientInformationFields?.forEach((field) => {
      if (field.include) {
        includedFields[field.field_name] = field.include;
      }
      mandatoryFields[field.field_name] = field.mandatory;
    });

    dispatch(setIncludedFields(includedFields));
    dispatch(setMandatoryFields(mandatoryFields));
  }, [patientInformationFields]);
};

const PatientInfo = ({ setActiveStep, handleSetSteps }) => {
  const { patientInformationFields } = useSelector(
    (state) => state.quickBookingReducer
  );
  usePatientFields(patientInformationFields);

  const { includedFields } = useSelector((state) => state.quickInputReducer);

  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () => {
    if (currentStep < enabledSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = () => {
    setActiveStep(3);
    handleSetSteps(2, 3);
  };

  const commonProps = {
    isDone: handleSubmit,
    onNext: handleNext,
    onPrevious: handlePrevious,
  };

  const patientInfoSteps = [
    {
      step: "personalDetails",
      isEnable: true,
      title: WORDINGS.PERSONAL_DETAILS,
      component: PersonalDetails,
      props: commonProps,
    },
    {
      step: "infectionStatus",
      isEnable:
        includedFields?.[PATIENT_FIELDS.SSI] ||
        includedFields?.[PATIENT_FIELDS.PEI],
      title: WORDINGS.INFECTION_STATUS,
      component: InfectionStatus,
      props: commonProps,
    },
    {
      step: "notes",
      isEnable: true,
      title: WORDINGS.NOTES,
      component: Notes,
      props: commonProps,
    },
  ];

  const enabledSteps = patientInfoSteps.filter((step) => step?.isEnable);
  const CurrentComponent = enabledSteps[currentStep]?.component || null;

  return (
    <>
      {CurrentComponent && (
        <CurrentComponent
          {...enabledSteps[currentStep].props}
          currentStep={currentStep}
          steps={enabledSteps}
        />
      )}
      {enabledSteps?.length > 1 && (
        <BottomNavigation currentStep={currentStep} steps={enabledSteps} />
      )}
    </>
  );
};

export default PatientInfo;
