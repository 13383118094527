import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPatientNotes, setTitle } from "../../action";
import { TextArea } from "../../../../components/TextArea";
import ButtonContainer from "../../../../components/ButtonContainer";
import { WORDINGS } from "../../../../helpers/constants";
import styles from "./index.module.css";

const Notes = (props) => {
  const { isDone } = props;
  const dispatch = useDispatch();

  const { patientNotes = "" } = useSelector((state) => state.quickInputReducer);

  const notesMaxLength = 150;
  const [notes, setNotes] = useState(patientNotes);

  useEffect(() => {
    dispatch(setTitle(`${WORDINGS.PATIENT_INFO} : ${WORDINGS.NOTES}`));
  }, []);

  const handleSubmitNotes = () => {
    dispatch(setPatientNotes(notes.trim()));
    isDone();
  };

  const submitProps = {
    ...props,
    isDone: handleSubmitNotes,
  };

  return (
    <div className={styles["patient-notes"]}>
      <TextArea
        className={styles["patient-notes-textbox"]}
        label={"Add your notes"}
        rows={3}
        value={notes}
        onChange={setNotes}
        maxLength={notesMaxLength}
        name={"notes"}
        id={"notes"}
        totalCount={notesMaxLength}
      />
      <ButtonContainer {...submitProps} />
    </div>
  );
};

export default Notes;
