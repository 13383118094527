import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PATIENT_FIELDS, WORDINGS } from "../../../helpers/constants";
import AdditionalItems from "./AdditionalItems";
import AnaesthesiaType from "./AnaesthesiaType";
import BottomNavigation from "../../../components/BottomNavigation";
import BloodBank from "./BloodBank";

const AdditionalInfo = ({ setActiveStep, handleSetSteps, setStep }) => {
  const { includedFields } = useSelector(
    (state) => state.quickInputReducer
  );
  const [currentStep, setCurrentStep] = useState(0);
  const handleNext = () => {
    if (currentStep < enabledSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = () => {
    setActiveStep(4);
    handleSetSteps(3, 4);
    setStep(3);
  };

  const commonProps = {
    isDone: handleSubmit,
    onNext: handleNext,
    onPrevious: handlePrevious,
  };

  const additionalItems = [
    {
      step: "anaesthesiaDetails",
      isEnable: includedFields?.[PATIENT_FIELDS.ANAESTHESIA],
      title: WORDINGS.ANAESTHESIA_DETAILS,
      component: AnaesthesiaType,
      props: commonProps,
    },
    {
      step: "bloodBankAssistance",
      isEnable: true,
      title: WORDINGS.BLOOD_BANK_ASSISTANCE,
      component: BloodBank,
      props: commonProps,
    },
    {
      step: "additionalItems",
      isEnable: true,
      title: WORDINGS.ADDITIONAL_ITEMS,
      component: AdditionalItems,
      props: commonProps,
    },
  ];

  const enabledSteps = additionalItems.filter((step) => step?.isEnable);
  const CurrentComponent = enabledSteps[currentStep]?.component || null;

  return (
    <>
      {CurrentComponent && (
        <CurrentComponent
          {...enabledSteps[currentStep].props}
          currentStep={currentStep}
          steps={enabledSteps}
        />
      )}
      {enabledSteps?.length > 1 && (
        <BottomNavigation currentStep={currentStep} steps={enabledSteps} />
      )}
    </>
  );
};

export default AdditionalInfo;
