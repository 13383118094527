import requestMethods from "../../../helpers/request";
import {
  getItemFromLocalStorage,
  handleAlertError,
} from "../../../helpers/utils";
import { QUICK_BOOKING_ACTIONS } from "./constants";

export const getQuickSlotAvailability =
  (unitId, params, stopLoader) => async (dispatch) => {
    const { company: { id: companyId = "" } = {} } =
      getItemFromLocalStorage("user") || {};

    const { surgeryStartTime, surgeryEndTime, surgeryTypeId } = params;
    try {
      const res = await requestMethods.POST({
        endpoint: `v1/ot/schedule/request/web/${companyId}/${unitId}/get-quick-booking-slots`,
        body: {
          surgery_start_time: surgeryStartTime,
          surgery_end_time: surgeryEndTime,
          surgery_type_id: surgeryTypeId,
          ot: "",
        },
      });

      if (res.status) {
        dispatch({
          type: QUICK_BOOKING_ACTIONS.GET_QUICK_BOOKING_AVAILABILITY_SUCCESS,
          payload: res,
        });
        if (stopLoader) stopLoader(false);
      }
    } catch (error) {
      dispatch(handleAlertError(error.message));
      if (stopLoader) stopLoader(false);
    }
  };

export const getPatientList =
  (unitId, params, stopLoader) => async (dispatch) => {
    try {
      const { company: { id: companyId = "" } = {} } =
        getItemFromLocalStorage("user") || {};

      const res = await requestMethods.GET({
        endpoint: `v1/ot/patient-record/web/${companyId}/${unitId}/get-all-patient-records`,
        params,
      });

      if (res.status) {
        dispatch({
          type: QUICK_BOOKING_ACTIONS.GET_PATIENT_LIST_SUCCESS,
          payload: res?.patient_record_details,
        });
        if (stopLoader) stopLoader();
      }
    } catch (error) {
      dispatch(handleAlertError(error.message));
      if (stopLoader) stopLoader();
    }
  };

export const getPatientInfoFields = (unitId) => async (dispatch) => {
  try {
    const { company: { id: companyId = "" } = {} } =
      getItemFromLocalStorage("user") || {};

    const res = await requestMethods.GET({
      endpoint: `v1/ot/settings/web/${companyId}/${unitId}/get-settings`,
    });

    if (res.status) {
      dispatch({
        type: QUICK_BOOKING_ACTIONS.GET_PATIENT_INFORMATION_FIELDS_SUCCESS,
        payload: res?.patient_information_fields?.patientInformationFields,
      });
    }
  } catch (error) {
    dispatch(handleAlertError(error.message));
  }
};

export const getSwcList = (unitId, stopLoader) => async (dispatch) => {
  try {
    const { company: { id: companyId = "" } = {} } =
      getItemFromLocalStorage("user") || {};

    const res = await requestMethods.GET({
      endpoint: `v1/ot/optional-masters/web/${companyId}/${unitId}/swc/get-ot-optional-masters`,
    });

    if (res.status) {
      dispatch({
        type: QUICK_BOOKING_ACTIONS.GET_SWC_LIST_SUCCESS,
        payload: res?.ot_optional_masters_list,
      });
      if (stopLoader) stopLoader();
    }
  } catch (error) {
    dispatch(handleAlertError(error.message));
    if (stopLoader) stopLoader();
  }
};

export const getPreExistInfections =
  (unitId, stopLoader) => async (dispatch) => {
    try {
      const { company: { id: companyId = "" } = {} } =
        getItemFromLocalStorage("user") || {};

      const res = await requestMethods.GET({
        endpoint: `v1/ot/optional-masters/web/${companyId}/${unitId}/pre_existing_infections/get-ot-optional-masters`,
      });

      if (res.status) {
        dispatch({
          type: QUICK_BOOKING_ACTIONS.GET_PEI_LIST_SUCCESS,
          payload: res?.ot_optional_masters_list,
        });
        if (stopLoader) stopLoader();
      }
    } catch (error) {
      dispatch(handleAlertError(error.message));
      if (stopLoader) stopLoader();
    }
  };

export const getTypeOfAnesthesia = (unitId, stopLoader) => async (dispatch) => {
  try {
    const { company: { id: companyId = "" } = {} } =
      getItemFromLocalStorage("user") || {};

    const res = await requestMethods.GET({
      endpoint: `v1/ot/optional-masters/web/${companyId}/${unitId}/type_of_anesthesia/get-ot-optional-masters`,
    });
    if (res.status) {
      dispatch({
        type: QUICK_BOOKING_ACTIONS.GET_ANAESTHESIA_LIST_SUCCESS,
        payload: res?.ot_optional_masters_list,
      });
      if (stopLoader) stopLoader();
    }
  } catch (error) {
    dispatch(handleAlertError(error.message));
    if (stopLoader) stopLoader();
  }
};
