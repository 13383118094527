import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getItemFromLocalStorage, history } from "../../helpers/utils";
import { GLOBAL_MESSAGES, PAGES, PAGE_DATA } from "../../helpers/constants";
import NewButton from "../../components/NewButton";
import successImage from "../../assets/successImage.png";
import { resetInputActions } from "../QuickBookingRequest/action";
import styles from "./index.module.css";

function RequestSuccess() {
  const dispatch = useDispatch();

  const nextPage =
    getItemFromLocalStorage(PAGE_DATA.REDIRECT_PAGE) || PAGES.REQUEST_OT;

  useEffect(() => {
    return () => {
      dispatch(resetInputActions());
    };
  }, []);

  return (
    <div className={styles["request-success"]}>
      <img src={successImage} alt="success_image" />
      <label>{GLOBAL_MESSAGES.OT_REQUEST_SUCCESS}</label>
      <NewButton largeButton onClick={() => history.push(`/${nextPage}`)}>
        {GLOBAL_MESSAGES.REQUEST_ANOTHER_OT}
      </NewButton>
      <NewButton largeButton onClick={() => history.push("/home")}>
        {GLOBAL_MESSAGES.BACK_TO_HOME}
      </NewButton>
    </div>
  );
}

export default RequestSuccess;
