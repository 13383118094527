import React from "react";
import styles from "./index.module.css";
import { joinClass } from "../../helpers/utils";

const BottomNavigation = ({ currentStep, steps }) => {
  return (
    <div className={styles["navigation-container"]}>
      <div className={styles["navigation-container__progress-indicator"]}>
        {steps.map((step, index) => (
          <div
            key={index}
            className={joinClass(
              styles["navigation-container__progress-step"],
              index === currentStep && styles.active
            )}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default BottomNavigation;
