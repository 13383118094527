import { INPUT_ACTIONS } from "./constants";

export const setIncludedFields = (includedFields) => {
  return { type: INPUT_ACTIONS.SET_INCLUDED_FIELDS, payload: includedFields };
};

export const setMandatoryFields = (mandatoryFields) => {
  return { type: INPUT_ACTIONS.SET_MANDATORY_FIELDS, payload: mandatoryFields };
};

export const setPatientInfo = (data) => {
  return { type: INPUT_ACTIONS.SET_PERSONAL_DETAILS, payload: data };
};

export const setInfectionStatus = (data) => {
  return { type: INPUT_ACTIONS.SET_INFECTION_STATUS, payload: data };
};

export const setPatientNotes = (data) => {
  return { type: INPUT_ACTIONS.SET_PATIENT_NOTES, payload: data };
};

export const setAnaesthesiaDetails = (data) => {
  return { type: INPUT_ACTIONS.SET_ANAESTHESIA_DETAILS, payload: data };
};

export const setConfirmationLogs = (data) => {
  return { type: INPUT_ACTIONS.SET_CONFIRMATION_LOGS, payload: data };
};

export const setBloodBankAssist = (data) => {
  return { type: INPUT_ACTIONS.SET_BLOODBANK_ASSIST, payload: data };
};

export const setAdditionalItems = (data) => {
  return { type: INPUT_ACTIONS.SET_ADDITIONAL_ITEMS, payload: data };
};

export const setTitle = (data) => {
  return { type: INPUT_ACTIONS.SET_TITLE, payload: data };
};

export const setSpecialityInfo = (data) => {
  return { type: INPUT_ACTIONS.SET_SPECIALITY_INFO, payload: data };
};

export const resetInputActions = () => {
  return { type: INPUT_ACTIONS.RESET_INPUT_ACTIONS };
};
