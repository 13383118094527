export const INPUT_ACTIONS = {
  SET_SURGERY_DATE: "SET_SURGERY_DATE",
  SET_SURGERY_DETAILS: "SET_SURGERY_DETAILS",
  EDIT_SURGERY_TIME: "EDIT_SURGERY_TIME",
  SET_OT: "SET_OT",
  SET_PERSONAL_DETAILS: "SET_PERSONAL_DETAILS",
  SET_INCLUDED_FIELDS: "SET_INCLUDED_FIELDS",
  SET_MANDATORY_FIELDS: "SET_MANDATORY_FIELDS",
  SET_INFECTION_STATUS: "SET_INFECTION_STATUS",
  SET_PATIENT_NOTES: "SET_PATIENT_NOTES",
  SET_ANAESTHESIA_DETAILS: "SET_ANAESTHESIA_DETAILS",
  SET_CONFIRMATION_LOGS: "SET_CONFIRMATION_LOGS",
  SET_BLOODBANK_ASSIST: "SET_BLOODBANK_ASSIST",
  SET_ADDITIONAL_ITEMS: "SET_ADDITIONAL_ITEMS",
  SET_TITLE: "SET_TITLE",
  SET_SPECIALITY_INFO: "SET_SPECIALITY_INFO",
  RESET_INPUT_ACTIONS: "RESET_INPUT_ACTIONS",
};
