import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBloodBankAssist, setTitle } from "../../action";
import ButtonContainer from "../../../../components/ButtonContainer";
import { RADIO_CONSTANTS, WORDINGS } from "../../../../helpers/constants";
import { handleAlertError } from "../../../../helpers/utils";
import RadioButton from "../../../../components/RadioButton";
import styles from "./index.module.css";

const BloodBank = (props) => {
  const { onNext } = props;
  const dispatch = useDispatch();

  const { confirmations, bloodBankAssist } = useSelector(
    (state) => state.quickInputReducer
  );

  const [isBloodBank, setBloodBank] = useState(bloodBankAssist);

  useEffect(() => {
    dispatch(
      setTitle(
        `${WORDINGS.ADDITIONAL_INFO} : ${WORDINGS.BLOOD_BANK_ASSISTANCE}`
      )
    );
  }, []);

  useEffect(() => {
    if (!bloodBankAssist) {
      if (confirmations?.includes("Blood_Bank")) {
        setBloodBank("yes");
      } else {
        setBloodBank("no");
      }
    }
  }, [confirmations]);

  const confirmItems = [
    {
      label: WORDINGS.YES,
      name: RADIO_CONSTANTS.YES,
    },
    {
      label: WORDINGS.NO,
      name: RADIO_CONSTANTS.NO,
    },
  ];

  const handleSubmit = () => {
    if (isBloodBank) {
      dispatch(setBloodBankAssist(isBloodBank));
      onNext();
    } else {
      dispatch(handleAlertError(WORDINGS.PLEASE_SELECT_ALL_REQUIRED_FIELDS));
    }
  };

  const buttonProps = {
    ...props,
    onNext: handleSubmit,
  };

  return (
    <div className={styles["blood-bank"]}>
      <div className={styles["blood-bank__items"]}>
        <label>{WORDINGS.IS_BLOOD_BANK_ASSISTANCE}</label>
        {confirmItems?.map((item, index) => (
          <RadioButton
            name={item?.name}
            value={isBloodBank}
            onChange={(value) => setBloodBank(value)}
            label={item?.label}
            key={index}
          />
        ))}
      </div>
      <ButtonContainer {...buttonProps} />
    </div>
  );
};

export default BloodBank;
