import React from "react";
import styles from "./index.module.css";
import { joinClass } from "../../helpers/utils";

const CheckBox = (props) => {
  const { id, head, label, checked, onChange, disabled, required } = props;

  return (
    <div
      className={joinClass(styles.checkbox, head && styles["checkbox--head"])}
    >
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        required={required}
      />
      <label
        htmlFor={id}
        className={joinClass(
          styles["checkbox__label"],
          disabled && styles["checkbox__disabled"]
        )}
      >
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
