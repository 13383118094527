import React from "react";
import { joinClass } from "../../helpers/utils";
import styles from "./index.module.css";

const RadioButton = (props) => {
  const { label, name, value, onChange, isIcon, disabled, icons, className } =
    props;
  // let IconComponent;
  // if (isIcon) IconComponent = icon;

  return (
    <label
      className={joinClass(
        styles.radio,
        disabled ? styles["radio--disabled"] : null,
        className
      )}
      htmlFor={name}
    >
      <input
        type="radio"
        id={name}
        className={styles.hidden}
        checked={value === name}
        onChange={(e) => onChange(e.target.value)}
        value={name}
        disabled={disabled}
      />
      <span className={styles.radioButton} />
      {/* {isIcon ? <IconComponent className={styles.radio__icon} /> : label} */}
      {(isIcon && icons.map((IconComponent) => <IconComponent />)) || label}
    </label>
  );
};

export default RadioButton;
