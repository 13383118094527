import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAnaesthesiaDetails, setTitle } from "../../action";
import ButtonContainer from "../../../../components/ButtonContainer";
import {
  PATIENT_FIELDS,
  RADIO_CONSTANTS,
  WORDINGS,
} from "../../../../helpers/constants";
import {
  getItemFromLocalStorage,
  handleAlertError,
} from "../../../../helpers/utils";
import CheckBox from "../../../../components/CheckBox";
import { getTypeOfAnesthesia } from "../../Actions/quickBookingActions";
import RadioButton from "../../../../components/RadioButton";
import { validateAnaesthesia } from "../../validations";
import styles from "./index.module.css";

const AnaesthesiaType = (props) => {
  const { onNext } = props;
  const dispatch = useDispatch();
  const { units = [] } = getItemFromLocalStorage("user") || {};

  const { anaesthesiaList } = useSelector((state) => state.quickBookingReducer);

  const {
    mandatoryFields,
    anaesthesia: { anaesthesiaTypes = [], pac },
    confirmations,
  } = useSelector((state) => state.quickInputReducer);

  const [selectedTypes, setSelectedTypes] = useState(anaesthesiaTypes);

  const [isPac, setPac] = useState("");

  useEffect(() => {
    dispatch(
      setTitle(`${WORDINGS.ADDITIONAL_INFO} : ${WORDINGS.ANAESTHESIA_DETAILS}`)
    );
    dispatch(getTypeOfAnesthesia(units[0]?.id));
  }, []);

  useEffect(() => {
    if (!pac) {
      if (confirmations?.includes("PAC")) {
        setPac("yes");
      } else {
        setPac("no");
      }
    } else {
      setPac(pac);
    }
  }, [confirmations]);

  const pacItems = [
    {
      label: WORDINGS.YES,
      name: RADIO_CONSTANTS.YES,
    },
    {
      label: WORDINGS.NO,
      name: RADIO_CONSTANTS.NO,
    },
  ];

  const handleCheckboxChange = (item) => {
    const { id, name, masterType, notes } = item;
    const selectedItem = { id, name, masterType, notes };

    setSelectedTypes((prevSelectedTypes) =>
      prevSelectedTypes.some((type) => type.id === id)
        ? prevSelectedTypes.filter((type) => type.id !== id)
        : [...prevSelectedTypes, selectedItem]
    );
  };

  const handleSubmit = () => {
    const isValid = validateAnaesthesia({
      selectedTypes,
      isPac,
      mandatoryFields,
    });
    if (isValid) {
      const data = {
        anaesthesiaTypes: selectedTypes,
        pac: isPac,
      };

      dispatch(setAnaesthesiaDetails(data));
      onNext();
    } else {
      dispatch(handleAlertError(WORDINGS.PLEASE_SELECT_ALL_REQUIRED_FIELDS));
    }
  };

  const buttonProps = {
    ...props,
    onNext: handleSubmit,
  };
  return (
    <div className={styles["anaesthesia"]}>
      <section className={styles["anaesthesia-types"]}>
        <label>
          {WORDINGS.SELECT_TYPE_OF_ANAESTHESIA}
          {mandatoryFields?.[PATIENT_FIELDS.ANAESTHESIA] && <span>*</span>}
        </label>
        {anaesthesiaList?.map((item, index) => (
          <CheckBox
            key={index}
            id={item.id}
            label={item.name}
            checked={selectedTypes.some((type) => type.id === item.id)}
            onChange={() => handleCheckboxChange(item)}
            advanced
          />
        ))}
      </section>
      <section className={styles["anaesthesia-types"]}>
        <label>{WORDINGS.IS_PAC_REQUIRED}</label>
        <div className={styles["anaesthesia-types__pac"]}>
          {pacItems?.map((item, index) => (
            <RadioButton
              name={item?.name}
              value={isPac}
              onChange={setPac}
              label={item?.label}
              key={index}
            />
          ))}
        </div>
      </section>
      <ButtonContainer {...buttonProps} />
    </div>
  );
};

export default AnaesthesiaType;
