import React from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import styles from "./index.module.css";
import { GLOBAL_CONSTANTS } from "../../helpers/constants";
import Button from "../Button";
import { joinClass } from "../../helpers/utils";

const ButtonContainer = (props) => {
  const { isDone, currentStep, steps, onPrevious, onNext } = props;

  return (
    <div
      className={styles["button-container__group"]}
      style={{ justifyContent: currentStep === 0 ? "center" : "space-between" }}
    >
      <div>
        {currentStep !== 0 && (
          <Button
            type="button"
            onClick={onPrevious}
            className={joinClass(styles["button-container__group__button"])}
          >
            <FaArrowLeftLong />
            <span>{GLOBAL_CONSTANTS.PREVIOUS}</span>
          </Button>
        )}
      </div>
      <div>
        {currentStep !== steps.length - 1 ? (
          <Button
            type="button"
            className={joinClass(
              styles["button-container__group__button"],
              styles["button-container__group__button__next"]
            )}
            onClick={onNext}
          >
            {GLOBAL_CONSTANTS.NEXT} : {steps[currentStep + 1]?.title}
          </Button>
        ) : (
          <Button
            type="button"
            className={joinClass(
              styles["button-container__group__button"],
              styles["button-container__group__button__next"]
            )}
            onClick={isDone}
          >
            {GLOBAL_CONSTANTS.DONE}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ButtonContainer;
