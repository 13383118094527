import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setInfectionStatus, setTitle } from "../../action";
import {
  getPreExistInfections,
  getSwcList,
} from "../../Actions/quickBookingActions";
import ButtonContainer from "../../../../components/ButtonContainer";
import {
  GLOBAL_CONSTANTS,
  GLOBAL_MESSAGES,
  PATIENT_FIELDS,
  WORDINGS,
} from "../../../../helpers/constants";
import AdvancedDropdown from "../../../../components/AdvancedDropdown";
import { TextArea } from "../../../../components/TextArea";
import RadioButton from "../../../../components/RadioButton";
import {
  findItemFromListById,
  getItemFromLocalStorage,
} from "../../../../helpers/utils";
import NewLoader from "../../../../components/NewLoader";
import { validateInfectionStatus } from "../../validations";
import styles from "./index.module.css";

const InfectionStatus = (props) => {
  const { onNext } = props;

  const dispatch = useDispatch();

  const { units = [] } = getItemFromLocalStorage("user") || {};
  const { swcList, peiList } = useSelector(
    (state) => state.quickBookingReducer
  );

  const { includedFields, mandatoryFields, infectionStatus } = useSelector(
    (state) => state.quickInputReducer
  );

  const [loadSwc, setLoadSwc] = useState(false);
  const [loadPei, setLoadPei] = useState(false);
  const [selectedPei, setSelectedPei] = useState(null);
  const [notes, setNotes] = useState("");
  const [errorMsg, setErrorMsg] = useState({ swc: "", pei: "", peiNotes: "" });
  const [selectedSwc, setSelectedSwc] = useState(null);
  const [selectedSwcName, setSelectedSwcName] = useState("");

  useEffect(() => {
    setLoadSwc(true);
    dispatch(getSwcList(units[0]?.id, () => setLoadSwc(false)));
    dispatch(getPreExistInfections(units[0]?.id, setLoadPei));
    dispatch(
      setTitle(`${WORDINGS.PATIENT_INFO} : ${WORDINGS.INFECTION_STATUS}`)
    );
  }, []);

  useEffect(() => {
    if (infectionStatus?.pei?.id || infectionStatus?.ssi?.id) {
      setSelectedPei(findItemFromListById(peiList, infectionStatus?.pei?.id));
      setSelectedSwc(findItemFromListById(swcList, infectionStatus?.ssi?.id));
      setSelectedSwcName(infectionStatus?.ssi?.name);
      setNotes(infectionStatus?.pei?.notes);
    } else {
      setSelectedSwcName(swcList?.[0]?.name);
      setSelectedSwc(swcList?.[0]);
    }
  }, [swcList, infectionStatus]);

  const handleSubmitInfections = () => {
    const valiDateFields = {
      selectedSwc,
      selectedPei,
      mandatoryFields,
      setErrorMsg,
      notes: notes.trim(),
    };
    if (validateInfectionStatus(valiDateFields)) {
      dispatch(
        setInfectionStatus({
          ssi: {
            id: selectedSwc?.id,
            name: selectedSwc?.name,
            notes: "",
          },
          pei: {
            id: selectedPei?.id,
            name: selectedPei?.name,
            notes: notes.trim(),
          },
        })
      );
      onNext();
    }
  };

  const submitProps = {
    ...props,
    onNext: handleSubmitInfections,
  };

  return (
    <div className={styles["infection-status"]}>
      {loadSwc || loadPei ? (
        <NewLoader />
      ) : (
        <>
          <section className={styles["infection-status_section"]}>
            {includedFields?.[PATIENT_FIELDS.SSI] && (
              <div className={styles["infection-status__swc-group"]}>
                <label>
                  {GLOBAL_CONSTANTS.SWC}
                  {mandatoryFields?.[PATIENT_FIELDS.SSI] && <span>*</span>}
                </label>
                <div className={styles["infection-status__list-swc"]}>
                  {swcList?.map((item, index) => (
                    <RadioButton
                      key={index}
                      label={item.name}
                      name={item?.name}
                      onChange={(value) => {
                        setSelectedSwcName(value);
                        setSelectedSwc(item);
                      }}
                      value={selectedSwcName}
                    />
                  ))}
                </div>
              </div>
            )}
            {includedFields?.[PATIENT_FIELDS.PEI] && (
              <>
                <div>
                  <AdvancedDropdown
                    label={"Are there any pre-existing infections?"}
                    labelClass={styles["infection-status__dropdownLabel"]}
                    options={peiList}
                    onChange={(value) => {
                      setSelectedPei(value);
                      setNotes("");
                    }}
                    value={selectedPei}
                    clearable
                    required={mandatoryFields?.[PATIENT_FIELDS.PEI]}
                    placeholder={GLOBAL_MESSAGES.SELECT}
                  />
                  <p className={styles["infection-status__error-msg"]}>
                    {errorMsg?.pei}
                  </p>
                </div>
                {selectedPei?.name === "Others" && (
                  <div>
                    <TextArea
                      label={"If others, please specify"}
                      rows={3}
                      value={notes}
                      onChange={setNotes}
                      required
                    />
                    <p className={styles["infection-status__error-msg"]}>
                      {errorMsg?.peiNotes}
                    </p>
                  </div>
                )}
              </>
            )}
          </section>
          <ButtonContainer {...submitProps} />{" "}
        </>
      )}
    </div>
  );
};

export default InfectionStatus;
