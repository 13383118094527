import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPatientInfo, setTitle } from "../../action";
import TextInput from "../../../../components/TextInput";
import NewButton from "../../../../components/NewButton";
import {
  getItemFromLocalStorage,
  parseInitialAge,
  patientRecordFilterByMrn,
} from "../../../../helpers/utils";
import {
  GLOBAL_CONSTANTS,
  GLOBAL_MESSAGES,
  PATIENT_FIELDS,
  WORDINGS,
} from "../../../../helpers/constants";
import { getPatientList } from "../../Actions/quickBookingActions";
import CreateDropdown from "../../../../components/CreateDropdown";
import { validatePersonalData } from "../../validations";
import styles from "./index.module.css";

const PersonalDetails = (props) => {
  const { steps, onNext } = props;

  const dispatch = useDispatch();

  const {
    units = [],
    enable_patient_records: isPatientRecords = false,
    crew_details: chiefSurgeon,
  } = getItemFromLocalStorage("user") || {};

  const { patientList = [] } = useSelector(
    (state) => state.quickBookingReducer
  );

  const { personalDetails, includedFields, mandatoryFields } = useSelector(
    (state) => state.quickInputReducer
  );

  const [patientName, setPatientName] = useState("");
  const [age, setAge] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [days, setDays] = useState("");
  const [mrNumber, setMrNumber] = useState("");
  const [uhidNumber, setUhidNumber] = useState("");
  const [patientDetailsLoader, setPatientDetailsLoader] = useState(false);
  const [patientNameRecords, setPatientNameRecords] = useState(
    patientRecordFilterByMrn(patientList, personalDetails?.mrn)?.[0]
  );
  const [errorMsg, setErrorMsg] = useState({
    patientName: "",
    age: "",
    mrn: "",
    uhid: "",
  });

  useEffect(() => {
    if (isPatientRecords && patientNameRecords) {
      setPatientName(patientNameRecords.name || "");
      setMrNumber(patientNameRecords?.mrn || "");
      // setAge(patientNameRecords?.age || "");
      parseInitialAge(
        patientNameRecords?.age || "",
        setYear,
        setMonth,
        setDays,
        setAge
      );
      setUhidNumber(patientNameRecords?.uhid || "");
    }
  }, [patientNameRecords]);

  const calculateAge = (inputYear, inputMonth, inputDay) => {
    const yearStr = inputYear ? `${inputYear} years` : "";
    const monthStr = inputMonth ? `${inputMonth} months` : "";
    const dayStr = inputDay ? `${inputDay} days` : "";

    const ageArray = [yearStr, monthStr, dayStr].filter(Boolean).join(", ");

    setAge(ageArray ? ageArray : "");
  };

  useEffect(() => {
    calculateAge(year, month, days);
  }, [year, month, days]);

  useEffect(() => {
    if (personalDetails) {
      setPatientName(personalDetails?.patientName || "");
      setMrNumber(personalDetails?.mrn || "");
      // setAge(personalDetails?.age || "");
      parseInitialAge(
        personalDetails?.age || "",
        setYear,
        setMonth,
        setDays,
        setAge
      );
      setUhidNumber(personalDetails?.uhid || "");
    }

    if (isPatientRecords) {
      setPatientDetailsLoader(true);
      const params = {
        status: "Active",
        departmentId: "",
        searchText: "",
        surgeonId: chiefSurgeon?.id || "",
        page: 1,
        size: 25,
        sort: 1,
        ignorePaging: true,
      };
      dispatch(getPatientList(units[0]?.id, params, setPatientDetailsLoader));
    }
    dispatch(
      setTitle(`${WORDINGS.PATIENT_INFO} : ${WORDINGS.PERSONAL_DETAILS}`)
    );
  }, []);

  const handleSubmit = () => {
    const validateFields = {
      patientName: patientName.trim(),
      mrNumber: mrNumber.trim(),
      uhidNumber: uhidNumber.trim(),
      age,
      year: year.trim(),
      month: month.trim(),
      days: days.trim(),
      mandatoryFields,
      includedFields,
      setErrorMsg,
    };
    const isValid = validatePersonalData(validateFields);
    if (isValid) {
      const data = {
        patientName: patientName.trim(),
        age: age.trim(),
        mrn: mrNumber.trim(),
        uhid: uhidNumber.trim(),
      };
      dispatch(setPatientInfo(data));
      onNext();
    }
  };

  return (
    <div className={styles["patient-info"]}>
      <div className={styles["patient-info__input-group"]}>
        {isPatientRecords ? (
          <CreateDropdown
            options={patientList}
            label={GLOBAL_MESSAGES.ENTER_PATIENT_NAME}
            value={patientNameRecords}
            onChange={(value) => setPatientNameRecords(value)}
            errorMsg={errorMsg?.patientName}
            required
            placeholder={"-Select-"}
            isLoading={patientDetailsLoader}
          />
        ) : (
          <div>
            <TextInput
              label={GLOBAL_MESSAGES.ENTER_PATIENT_NAME}
              value={patientName}
              onChange={(value) => {
                setPatientName(value);
              }}
              required
              autoFocus
            />
            <p className={styles["patient-info__error-msg"]}>
              {errorMsg?.patientName}
            </p>
          </div>
        )}
        {includedFields?.[PATIENT_FIELDS.AGE] && (
          <div>
            <label className={styles["patient-info__age-label"]}>
              {GLOBAL_MESSAGES.HOW_OLD_IS_YOUR_AGE}
              {mandatoryFields?.[PATIENT_FIELDS.AGE] && <span>*</span>}
            </label>
            <div className={styles["patient-info__age-data"]}>
              <TextInput
                value={year}
                onChange={(value) => setYear(value)}
                className={styles["patient-info__age-input"]}
              />
              <label className={styles["patient-info__age__data-label"]}>
                {GLOBAL_CONSTANTS.YEARS}
              </label>
              <TextInput
                value={month}
                onChange={(value) => setMonth(value)}
                className={styles["patient-info__age-input"]}
              />
              <label className={styles["patient-info__age__data-label"]}>
                {GLOBAL_CONSTANTS.MONTHS}
              </label>
              <TextInput
                value={days}
                onChange={(value) => setDays(value)}
                className={styles["patient-info__age-input"]}
              />
              <label className={styles["patient-info__age__data-label"]}>
                {GLOBAL_CONSTANTS.DAYS}
              </label>
            </div>
            <p className={styles["patient-info__error-msg"]}>{errorMsg?.age}</p>
          </div>
        )}
        {includedFields?.[PATIENT_FIELDS.UHID] && (
          <div>
            <TextInput
              label={GLOBAL_MESSAGES.UHID}
              value={uhidNumber}
              onChange={(value) => setUhidNumber(value)}
              required={mandatoryFields?.[PATIENT_FIELDS.UHID]}
            />
            <p className={styles["patient-info__error-msg"]}>
              {errorMsg?.uhid}
            </p>
          </div>
        )}
        {includedFields?.[PATIENT_FIELDS.MRN] && (
          <div>
            <TextInput
              label={GLOBAL_MESSAGES.MR_NUMBER}
              value={mrNumber}
              onChange={(value) => setMrNumber(value)}
              required={mandatoryFields?.[PATIENT_FIELDS.MRN]}
            />
            <p className={styles["patient-info__error-msg"]}>{errorMsg?.mrn}</p>
          </div>
        )}
      </div>
      <div className={styles["patient-info__proceed-button-div"]}>
        <NewButton
          largeButton
          className={styles["patient-info__proceed-button"]}
          onClick={handleSubmit}
        >
          {`${GLOBAL_CONSTANTS.NEXT}: ${steps?.[1]?.title}`}
        </NewButton>
      </div>
    </div>
  );
};

export default PersonalDetails;
