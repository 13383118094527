import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  BUTTON_TYPES,
  DATE_TIME_FORMAT,
  WORDINGS,
} from "../../../helpers/constants";
import NewCalender from "../../../components/NewCalendar";
import NewButton from "../../../components/NewButton";
import { checkTimeDifference } from "../../../helpers/utils";
import styles from "./index.module.css";
import { useDispatch } from "react-redux";
import { INPUT_ACTIONS } from "../constants";
import { setSpecialityInfo } from "../action";

const DateSelection = (props) => {
  const {
    units,
    surgeryDate,
    closureTime,
    setClosureTime,
    setSurgeryDate,
    setStep,
    chiefSurgeon,
    speciality,
  } = props;

  const dispatch = useDispatch();

  const [selectDate, setSelectDate] = useState(moment(surgeryDate));
  const [tempCheckDate, setTempCheckDate] = useState(false);
  const [tempCheckToday, setTempCheckToday] = useState(false);
  const [tempBookingMode, setTempBookingMode] = useState(false);

  useEffect(() => {
    setTempCheckDate(selectDate.isAfter(moment().add(1, DATE_TIME_FORMAT.DAY)));
    setTempCheckToday(selectDate.isSame(moment(), DATE_TIME_FORMAT.DAY));
    setClosureTime(checkTimeDifference(units[0]?.ot_booking_closure_time));
    setTempBookingMode((!tempCheckDate && !closureTime) || tempCheckToday);
  }, [selectDate, tempCheckDate, closureTime, tempCheckToday]);

  const handleSubmit = () => {
    setSurgeryDate(selectDate);
    dispatch({
      type: INPUT_ACTIONS.SET_SURGERY_DATE,
      payload: +moment(selectDate),
    });
    dispatch(setSpecialityInfo({ chiefSurgeon, speciality }));
    setStep(1);
  };

  return (
    <div className={styles["date-selection"]}>
      <h2 className={styles["date-selection__title"]}>
        {WORDINGS.SELECT_SURGERY_DATE}
      </h2>
      <label className={styles["date-selection__selected-date"]}>
        {`${WORDINGS.SURGERY_DATE} : 
              ${moment(selectDate).format(DATE_TIME_FORMAT.Do_MMMM_YYYY)}`}
      </label>
      <NewCalender value={selectDate} setValue={setSelectDate} />
      <span className={styles["date-selection__unplanned"]}>
        {tempBookingMode && WORDINGS.UNPLANNED_ALERT}
      </span>
      <NewButton
        className={styles["date-selection__done-button"]}
        largeButton
        onClick={handleSubmit}
      >
        {BUTTON_TYPES.PROCEED}
      </NewButton>
    </div>
  );
};

export default DateSelection;
